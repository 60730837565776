import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { useMemo } from 'react'

export const useProfileParameters = () => {
    const [profileData] = useProfileData()

    return useMemo(() => {
        let age, gender, wh_uuid, emailSha256Hash
        if (profileData === 'unsure') {
            return 'unsure'
        }

        if (isUserLoggedIn(profileData)) {
            const birthyear = parseInt(profileData.birthYear, 10)
            age = birthyear !== undefined ? new Date().getFullYear() - birthyear : undefined
            wh_uuid = profileData.uuid
            gender = profileData.gender
            emailSha256Hash = profileData.emailSha256Hash
        }
        return { gender, age, wh_uuid, emailSha256Hash }
    }, [profileData])
}
