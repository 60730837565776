import React, { Fragment, useEffect } from 'react'
import { ExpressNextContextWithUserData, GetInitialPropsReturnType, PropsIndexSignature } from '@wh/common/chapter/types/nextJS'
import { NextComponentType, NextPage } from 'next'
import { extractMetaTagInfo, getMetaTags, SeoPageName } from '@wh/common/chapter/api/tagging/metaTagApiClient'
import { SeoMetaTagsForSeoMetaTagsInfo } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import { META_TAGS_LOCAL_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { staticRelativeCanonicals } from './staticRelativeCanonicals'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { MetaTags } from '@wh/common/chapter/api/tagging/metaTagCache'

/** Pages that needs a  <meta name="robots" content="noindex" /> */
const noIndexPages: SeoPageName[] = [
    'myprofile',
    'myprofile.myadverts',
    'myprofile.mysearches',
    'myprofile.myfindings',
    'myprofile.edituser',
    'myprofile.mytransactions',
    'myprofile.edittenant',
    'myprofile.edittenant.preview',
    'myprofile.edittenant.myexchanges',
    'bap.all.browse2',
]

/** Pages that need an explicit follow meta tag <meta name="robots" content="noindex, follow" /> */
const noIndexButFollowPages: SeoPageName[] = ['bap.all.browse2']

// we do not need canonicals for pages where we either 1. get the canonical from the api (dynamic routes like result list and ad detail), or 2. which are not SEO-relevant traffic-wise
const relativeCanonicalUrlMap: { [K in SeoPageName]: string | undefined } = {
    '.': staticRelativeCanonicals.Home,
    agb: undefined,
    ad_rules: undefined,
    privacy_policy: undefined,
    imprint: undefined,
    terms_of_use: undefined,
    presse: undefined,
    security_hints_sell: undefined,
    security_hints_account: undefined,
    security_hints_puppy: undefined,
    security_hints: undefined,
    myprofile: undefined,
    'myprofile.register': undefined,
    'myprofile.myadverts': undefined,
    'myprofile.mysearches': undefined,
    'myprofile.myfindings': undefined,
    'myprofile.edituser': undefined,
    'myprofile.mytransactions': undefined,
    'myprofile.edittenant': undefined,
    'myprofile.edittenant.preview': undefined,
    'myprofile.edittenant.myexchanges': undefined,
    'myprofile.mylastsearches': undefined,
    car: staticRelativeCanonicals.MotorHome,
    'car.used.browse1': staticRelativeCanonicals.MotorCarHome,
    'car.mc.browse1': staticRelativeCanonicals.MotorMcHome,
    'car.used.detailsearch': staticRelativeCanonicals.MotorCarDetailSearch,
    'car.mc.detailsearch': staticRelativeCanonicals.MotorMcDetailSearch,
    'car.truck.detailsearch': staticRelativeCanonicals.MotorTruckDetailSearch,
    'car.caravan.detailsearch': staticRelativeCanonicals.MotorCaravanDetailSearch,
    realestate: staticRelativeCanonicals.EstateHome,
    'realestate.house_sale.detailsearch': staticRelativeCanonicals.EstateHouseSaleDetailSearch,
    'realestate.house_rent.detailsearch': staticRelativeCanonicals.EstateHouseRentDetailSearch,
    'realestate.appartment_sale.detailsearch': staticRelativeCanonicals.EstateFlatSaleDetailSearch,
    'realestate.appartment_rent.detailsearch': staticRelativeCanonicals.EstateFlatRentDetailSearch,
    'realestate.business_sale.detailsearch': staticRelativeCanonicals.EstateCommercialSaleDetailSearch,
    'realestate.business_rent.detailsearch': staticRelativeCanonicals.EstateCommercialRentDetailSearch,
    'realestate.leisure_sale.detailsearch': staticRelativeCanonicals.EstateLeisureSaleDetailSearch,
    'realestate.leisure_rent.detailsearch': staticRelativeCanonicals.EstateLeisureRentDetailSearch,
    'realestate.plot_sale.detailsearch': staticRelativeCanonicals.EstatePlotsDetailSearch,
    'realestate.misc.detailsearch': staticRelativeCanonicals.EstateMiscDetailSearch,
    'realestate.common.detailsearch': staticRelativeCanonicals.EstateCommonDetailSearch,
    'realestate.nc.detailsearch': staticRelativeCanonicals.EstateCommonDetailSearch,
    bap: staticRelativeCanonicals.BapHome,
    'bap.free': staticRelativeCanonicals.BapFree,
    'bap.all.browse2': undefined,
}

interface WithMetaTagsProps {
    pageProps: PropsIndexSignature
    metaTags?: MetaTags
}

// actually <T extends ExpressNextContextWithUserData> would make more sense, but that does not compile for whatever reason
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withMetaTags = (pageName: SeoPageName) => (WrappedComponent: NextComponentType<any, any, any>) => {
    const HocComponent: NextPage<WithMetaTagsProps> = (props) => {
        const { metaTagInfo, canonicalUrl, noIndex, noFollow } = useMetaTags(props.metaTags, pageName)

        return (
            <Fragment>
                <SeoMetaTagsForSeoMetaTagsInfo
                    metaTagInfo={metaTagInfo}
                    canonicalUrl={canonicalUrl}
                    noindex={noIndex}
                    nofollow={noFollow}
                />
                <WrappedComponent {...props.pageProps} />
            </Fragment>
        )
    }

    HocComponent.getInitialProps = async (ctx: ExpressNextContextWithUserData) => {
        let pageProps: PropsIndexSignature = {}
        if (WrappedComponent.getInitialProps) {
            pageProps = (await (WrappedComponent.getInitialProps(ctx) as GetInitialPropsReturnType)) || {}
        }

        const metaTags = await fetchMetaTagsInGetInitialProps(ctx.req)

        return { pageProps, metaTags }
    }

    return HocComponent
}

export const fetchMetaTagsInGetInitialProps = async (req: ExpressNextContextWithUserData['req']) => {
    const isServer = !!req
    let metaTags: MetaTags | undefined
    try {
        if (isServer) {
            metaTags = await getMetaTags(req)
        } else if (storageAvailable('localStorage')) {
            const metaTagsJson = localStorage.getItem(META_TAGS_LOCAL_STORAGE_KEY)
            if (metaTagsJson) {
                metaTags = JSON.parse(metaTagsJson) as MetaTags | undefined
            }
        }
    } catch {
        // ignore meta tags request error
    }

    return metaTags
}

export const useMetaTags = (metaTags: MetaTags | undefined, pageName: SeoPageName) => {
    useEffect(() => {
        if (metaTags && storageAvailable('localStorage')) {
            localStorage.setItem(META_TAGS_LOCAL_STORAGE_KEY, JSON.stringify(metaTags))
        } else {
            console.info('Please enable cookies')
        }
    }, [metaTags])

    return { metaTagInfo: extractMetaTagInfo(metaTags, pageName), ...getAdditionalSeoProperties(pageName) }
}

export const getAdditionalSeoProperties = (pageName: SeoPageName) => {
    const relativeCanonicalUrl = relativeCanonicalUrlMap[pageName]
    const canonicalUrl = relativeCanonicalUrl ? `https://www.willhaben.at${relativeCanonicalUrl}` : undefined
    const noIndex = noIndexPages.indexOf(pageName) !== -1

    const noIndexButFollow = noIndexButFollowPages.indexOf(pageName) !== -1
    const noFollow = noIndexButFollow ? false : noIndex

    return { canonicalUrl, noIndex, noFollow }
}
