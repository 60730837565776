export interface MetaTagInfo {
    title: string
    pageName: string
    metaDescription: string
    metaKeywords: string
}

export interface MetaTags {
    [metaTagName: string]: MetaTagInfo
}

export class MetaTagCache {
    private static CACHE_VALIDITY_MS = 300 * 1000
    private static loadingDate?: Date
    private static metaTags?: MetaTags

    static setMetaTags(metaTags: MetaTags) {
        MetaTagCache.loadingDate = new Date()
        MetaTagCache.metaTags = metaTags
    }

    static getCachedMetaTags(): MetaTags | undefined {
        if (MetaTagCache.isCacheValid()) {
            return MetaTagCache.metaTags
        } else {
            return undefined
        }
    }

    private static isCacheValid() {
        if (!MetaTagCache.metaTags || !MetaTagCache.loadingDate) {
            return false
        }
        const entryAge = new Date().getTime() - MetaTagCache.loadingDate.getTime()
        const isEntryExpired = entryAge > MetaTagCache.CACHE_VALIDITY_MS
        return !isEntryExpired
    }
}
