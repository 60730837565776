import React, { FunctionComponent, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { RenderSlotId } from '@wh/common/digital-advertising/config'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { css } from 'styled-components'
import { werbungPlaceholderEmptyAfter } from '@wh/common/digital-advertising/components/werbungPlaceholderEmptyAfter'
import { werbungBeforeCss } from '../werbungBeforeCss'

export const ContentAdLargeRenderSlot: FunctionComponent = () => {
    const advertisingState = useContext(AdvertisingStateContext)
    const isClosedAdSlot = (advertisingState.pageModifications.closedAdSlots || []).includes(RenderSlotId.CONTENT_AD_LARGE)

    // display:flex implicitly makes child elements display:block, which is necessary to avoid the extra spacing at the bottom
    return (
        <Box
            flex="1 1 auto"
            justifyContent="center"
            width="100%"
            id={RenderSlotId.CONTENT_AD_LARGE}
            aria-hidden="true"
            position="relative"
            zIndex="content"
            css={css`
                iframe {
                    /* avoids extra spacing at the bottom */
                    display: block;
                }

                display: none;
                ${(p) => p.theme.media.desktop} {
                    display: ${isClosedAdSlot ? 'none' : 'flex'};
                }

                ${(p) => p.theme.media.print} {
                    display: none;
                }

                /* set size only during loading, afterwards should jump to size of Advertisement */
                &:empty {
                    width: 300px;
                    height: 250px;
                }

                /* defines :after */
                ${werbungPlaceholderEmptyAfter(250)}
                ${werbungBeforeCss}
            `}
        />
    )
}
