import { OPEN_GRAPH } from '@wh/common/chapter/lib/config/constants-seo'
import { MetaTagCache, MetaTagInfo, MetaTags } from './metaTagCache'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { NextRequest } from '../../types/nextJS'
import { fetcher } from '../fetcher'

// corresponds to pageName ids in the seometatags response
export type SeoPageName =
    | '.'
    | 'agb'
    | 'ad_rules'
    | 'privacy_policy'
    | 'imprint'
    | 'terms_of_use'
    | 'presse'
    | 'security_hints_sell'
    | 'security_hints_account'
    | 'security_hints_puppy'
    | 'security_hints'
    | 'myprofile'
    | 'myprofile.register'
    | 'myprofile.myadverts'
    | 'myprofile.mysearches'
    | 'myprofile.myfindings'
    | 'myprofile.edituser'
    | 'myprofile.edittenant'
    | 'myprofile.edittenant.preview'
    | 'myprofile.edittenant.myexchanges'
    | 'myprofile.mylastsearches'
    | 'myprofile.mytransactions'
    | 'car'
    | 'car.used.browse1'
    | 'car.mc.browse1'
    | 'car.used.detailsearch'
    | 'car.mc.detailsearch'
    | 'car.truck.detailsearch'
    | 'car.caravan.detailsearch'
    | 'realestate'
    | 'realestate.house_sale.detailsearch'
    | 'realestate.house_rent.detailsearch'
    | 'realestate.appartment_sale.detailsearch'
    | 'realestate.appartment_rent.detailsearch'
    | 'realestate.business_sale.detailsearch'
    | 'realestate.business_rent.detailsearch'
    | 'realestate.leisure_sale.detailsearch'
    | 'realestate.leisure_rent.detailsearch'
    | 'realestate.plot_sale.detailsearch'
    | 'realestate.misc.detailsearch'
    | 'realestate.common.detailsearch'
    | 'realestate.nc.detailsearch'
    | 'bap'
    | 'bap.free'
    | 'bap.all.browse2'

export const DEFAULT_META_TAGS: MetaTagInfo = {
    title: OPEN_GRAPH.TITLE,
    pageName: OPEN_GRAPH.SITE_NAME,
    metaDescription: OPEN_GRAPH.DESCRIPTION,
    metaKeywords:
        'gebrauchtwagen, immobilien, jobs, ' +
        'auto, marktplatz, kostenlose kleinanzeigen,' +
        'immo, wohnungen, häuser, ' +
        'mietwohnung, inserieren, kaufen, ' +
        'verkaufen, flohmarkt',
}

const loadMetaTags: (request?: NextRequest) => Promise<MetaTagInfo[]> = (request?: NextRequest) =>
    fetcher<MetaTagInfo[]>(`/iad/seometatags`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })

const fetchMetaTags = async (request?: NextRequest): Promise<MetaTags> => {
    const metaTagsDto: MetaTagInfo[] = await loadMetaTags(request)

    return metaTagsDto.reduce((acc: MetaTags, tag: MetaTagInfo) => {
        acc[tag.pageName] = tag
        return acc
    }, {})
}

export const getMetaTagInfo = async (pageName: SeoPageName, request?: NextRequest): Promise<MetaTagInfo> => {
    return extractMetaTagInfo(await getMetaTags(request), pageName)
}

export const getMetaTags = async (request?: NextRequest): Promise<MetaTags> => {
    const cachedMetaTags = MetaTagCache.getCachedMetaTags()
    if (cachedMetaTags) {
        return cachedMetaTags
    }
    const fetchedMetaTags = await fetchMetaTags(request)
    MetaTagCache.setMetaTags(fetchedMetaTags)

    return fetchedMetaTags
}

export const extractMetaTagInfo = (metaTags: MetaTags | undefined, pageName: SeoPageName) => {
    return metaTags?.[pageName] ?? DEFAULT_META_TAGS
}
