import { SeoMetaTagsForSeoMetaTagsInfo } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import React, { FunctionComponent } from 'react'
import { getAdditionalSeoProperties } from '@wh/common/chapter/hocs/withMetaTags'
import { MetaTagInfo } from '@wh/common/chapter/api/tagging/metaTagCache'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { AutoMotorAdTypeCounters } from '@bbx/search-journey/common/api/searchApiClient'
import { useVerticalsInfo } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { DEFAULT_META_TAGS, SeoPageName } from '@wh/common/chapter/api/tagging/metaTagApiClient'

type SeoPageNameOption = Extract<
    SeoPageName,
    'realestate' | 'car' | 'car.used.browse1' | 'car.mc.browse1' | 'bap' | 'bap.free' | 'bap.all.browse2' | '.'
>

type StartpageSeoMetaTagsProps = {
    metaTagInfo: MetaTagInfo | undefined
    seoPageName: SeoPageNameOption
    verticalId: 'ESTATE' | 'BAP' | 'MOTOR'
    adTypeCounters?: AutoMotorAdTypeCounters
    categoryName?: string
    nrOfAdvertsFromSearch?: number
}

export const StartpageSeoMetaTags: FunctionComponent<StartpageSeoMetaTagsProps> = ({
    metaTagInfo,
    seoPageName,
    verticalId,
    adTypeCounters,
    categoryName,
    nrOfAdvertsFromSearch,
}) => {
    const localMetaTagInfo = metaTagInfo ?? DEFAULT_META_TAGS
    const { canonicalUrl, noIndex, noFollow } = getAdditionalSeoProperties(seoPageName)
    const [verticalsInfo] = useVerticalsInfo()

    const nrOfAdverts = verticalsInfo?.find((vertical) => vertical.id === verticalIdMap[verticalId])?.nrOfAdverts

    const newMetaTagInfo = {
        ...localMetaTagInfo,
        metaDescription: replacePlaceholder(
            localMetaTagInfo.metaDescription,
            nrOfAdverts,
            seoPageName,
            adTypeCounters,
            categoryName,
            nrOfAdvertsFromSearch,
        ),
        title: replacePlaceholder(localMetaTagInfo.title, nrOfAdverts, seoPageName, adTypeCounters, categoryName, nrOfAdvertsFromSearch),
    }

    return <SeoMetaTagsForSeoMetaTagsInfo metaTagInfo={newMetaTagInfo} canonicalUrl={canonicalUrl} noindex={noIndex} nofollow={noFollow} />
}

const replacePlaceholder = (
    value: string,
    nrOfAdverts: number | undefined,
    seoPageName: SeoPageNameOption,
    adTypeCounters?: AutoMotorAdTypeCounters,
    categoryName?: string,
    nrOfAdvertsFromSearch?: number,
) => {
    switch (seoPageName) {
        case 'bap':
            return value.replace('#numads#', nrOfAdvertsFromSearch ? formatNumber(nrOfAdvertsFromSearch) : '')
        case 'bap.free':
            return value.replace('#numads#', nrOfAdvertsFromSearch ? formatNumber(nrOfAdvertsFromSearch) : '')
        case 'bap.all.browse2':
            return value
                .replace('#categorysearchnumads#', nrOfAdvertsFromSearch ? formatNumber(nrOfAdvertsFromSearch) : '')
                .replace('#category#', categoryName ?? '')
        case 'car':
            return value.replace('#verticalNumads#', nrOfAdverts ? formatNumber(nrOfAdverts) : '')
        case 'car.used.browse1':
            return value.replace('#searchnumads#', adTypeCounters ? formatNumber(adTypeCounters.cars) : '')
        case 'car.mc.browse1':
            return value.replace('#searchnumads#', adTypeCounters ? formatNumber(adTypeCounters.motorbikes) : '')
        default:
            return value.replace('#verticalNumads#', nrOfAdverts ? formatNumber(nrOfAdverts) : '')
    }
}
